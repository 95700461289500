
import Vue from 'vue';

export default Vue.extend({
  name: 'Cover',

  props: {
    showChangeCoverButton: {
      type: Boolean,
      default: false,
    },

    coverUrl: {
      type: String,
      default: '',
    },

    loading: {
      type: Boolean as () => boolean,
      default: false,
    },
  },

  data() {
    return {
      url: '',
    };
  },

  watch: {
    url(newUrl) {
      this.$emit('update:cover-url', newUrl);
    },
  },

  created() {
    this.url = this.coverUrl;
  },

  methods: {
    onFileChange(event: any) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        const result = (): any => reader.result;
        this.url = result();
      };

      reader.readAsDataURL(file);
    },
  },
});
