var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("ValidationObserver", { attrs: { slim: "" } }, [
    _c("div", { staticClass: "p-fluid p-formgrid p-grid" }, [
      _c(
        "div",
        { staticClass: "p-field p-col" },
        [
          _c("input-validation", {
            attrs: {
              name: "firstname",
              rules: "required|alpha_spaces|min:2|max:40",
              label: _vm.$t("managerProfile.fields.user.name.label"),
              mode: "lazy",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("InputText", {
                      class: { "p-invalid": errors[0] },
                      attrs: {
                        placeholder: _vm.$t(
                          "managerProfile.fields.user.name.placeholder"
                        ),
                      },
                      model: {
                        value: _vm.value.firstName,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "firstName", $$v)
                        },
                        expression: "value.firstName",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "p-field p-col" },
        [
          _c("input-validation", {
            attrs: {
              name: "lastname",
              rules: "required|alpha_spaces|min:2|max:40",
              label: _vm.$t("managerProfile.fields.user.surname.label"),
              mode: "lazy",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("InputText", {
                      class: { "p-invalid": errors[0] },
                      attrs: {
                        placeholder: _vm.$t(
                          "managerProfile.fields.user.name.placeholder"
                        ),
                      },
                      model: {
                        value: _vm.value.lastName,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "lastName", $$v)
                        },
                        expression: "value.lastName",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "p-fluid p-formgrid p-grid" }, [
      _c(
        "div",
        { staticClass: "p-field p-col-4" },
        [
          _c("input-validation", {
            attrs: {
              name: "mail",
              rules: "required|email|max:40",
              label: _vm.$t("managerProfile.fields.user.mail.label"),
              mode: "lazy",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("InputText", {
                      class: { "p-invalid": errors[0] },
                      attrs: {
                        placeholder: _vm.$t(
                          "managerProfile.fields.user.mail.placeholder"
                        ),
                      },
                      model: {
                        value: _vm.value.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "email", $$v)
                        },
                        expression: "value.email",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "p-field p-col-2" },
        [
          _c("label", { staticClass: "fs14" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("managerProfile.fields.user.cellphone.label")) +
                " "
            ),
          ]),
          _c("InputText", {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: "(##) 9####-####",
                expression: "'(##) 9####-####'",
              },
            ],
            attrs: {
              placeholder: _vm.$t(
                "managerProfile.fields.user.cellphone.placeholder"
              ),
            },
            model: {
              value: _vm.value.phoneNumber,
              callback: function ($$v) {
                _vm.$set(_vm.value, "phoneNumber", $$v)
              },
              expression: "value.phoneNumber",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "p-field p-col-2" },
        [
          _c("label", { staticClass: "fs14" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("managerProfile.fields.user.phone.label")) +
                " "
            ),
          ]),
          _c("InputText", {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: "(##) ####-####",
                expression: "'(##) ####-####'",
              },
            ],
            attrs: {
              placeholder: _vm.$t(
                "managerProfile.fields.user.phone.placeholder"
              ),
            },
            model: {
              value: _vm.value.residentialPhoneNumber,
              callback: function ($$v) {
                _vm.$set(_vm.value, "residentialPhoneNumber", $$v)
              },
              expression: "value.residentialPhoneNumber",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }