var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "user-credentials" } },
    [
      _c("TitleComponent", {
        attrs: { title: _vm.$t("managerProfile.userAndPassword") },
      }),
      _c("Field", { attrs: { field: _vm.user } }),
      _c("div", { staticClass: "p-grid" }, [
        (_vm.showPassword ? "text" : "password") === "checkbox"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password",
                },
              ],
              staticClass: "p-col p-d-inline input-password",
              attrs: { disabled: "", readonly: "", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.password)
                  ? _vm._i(_vm.password, null) > -1
                  : _vm.password,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.password,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.password = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.password = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.password = $$c
                  }
                },
              },
            })
          : (_vm.showPassword ? "text" : "password") === "radio"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password",
                },
              ],
              staticClass: "p-col p-d-inline input-password",
              attrs: { disabled: "", readonly: "", type: "radio" },
              domProps: { checked: _vm._q(_vm.password, null) },
              on: {
                change: function ($event) {
                  _vm.password = null
                },
              },
            })
          : _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password",
                },
              ],
              staticClass: "p-col p-d-inline input-password",
              attrs: {
                disabled: "",
                readonly: "",
                type: _vm.showPassword ? "text" : "password",
              },
              domProps: { value: _vm.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.password = $event.target.value
                },
              },
            }),
        _c("div", { staticClass: "p-col" }, [
          _c(
            "div",
            {
              staticClass: "icon-left-password",
              on: {
                click: function ($event) {
                  _vm.showPassword = !_vm.showPassword
                },
              },
            },
            [
              _c("img", {
                staticClass: "mr-2",
                attrs: {
                  width: "20",
                  height: _vm.showPassword ? "18" : "13",
                  src: _vm.showPassword
                    ? require("@/common/assets/icons/hide.svg")
                    : require("@/common/assets/icons/eye.svg"),
                  alt: _vm.showPassword ? _vm.$t("hide") : _vm.$t("show"),
                },
              }),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }