
import mixins from 'vue-typed-mixins';
import { mapMutations } from 'vuex';
import PageCard from '@/common/template/PageCard.vue';
import NotificationMixin from '@/mixins/NotificationMixin';
import AccessPassFormModal from '@/resources/access-pass/components/modals/AccessPassFormModal.vue';
import SupplierRegistrationFormModal from '@/resources/access-pass/components/modals/SupplierRegistrationFormModal.vue';
import { HeaderSection, ContentSection } from '@/resources/access-pass/components/sections';
import { AccessPass } from '@/resources/access-pass/types';

export default mixins(NotificationMixin).extend({
  name: 'AccessPass',
  components: {
    PageCard,
    HeaderSection,
    ContentSection,
    AccessPassFormModal,
    SupplierRegistrationFormModal,
  },
  data() {
    return {
      showModal: false,
      tableRefresh: false,
      tableRows: [] as AccessPass[],
      tableLoading: true,
      editItem: null as null | AccessPass,
      searchText: '',
    };
  },

  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),

    showFormModal() {
      this.showModal = true;
    },
    hideFormModal() {
      this.showModal = false;
    },
    edit(item: AccessPass) {
      this.editItem = item;
      this.showFormModal();
    },
    async search(search: string) {
      this.searchText = search;
    },
  },
  watch: {
    showModal(showModal) {
      if (!showModal) {
        this.editItem = null;
      }
    },
  },
});
