var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "container-fluid pb-1" }, [
    _vm._m(0),
    _c("div", { staticClass: "row p-ai-end" }, [
      _c(
        "div",
        { staticClass: "col-12 col-sm-12 col-md-4 p-field" },
        [
          _c("Button", {
            ref: "btnRegisterUsers",
            staticClass: "p-button-raised",
            attrs: {
              icon: "icon-r-circle-plus bg-white",
              "icon-pos": "left",
              label: "Cadastrar Senhas de Acessos",
            },
            on: {
              click: function ($event) {
                return _vm.showFormCreate()
              },
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "col-12 col-sm-12 col-md-3 ml-auto p-field" }, [
        _c(
          "span",
          { staticClass: "p-input-icon-left w-100" },
          [
            _c("i", { staticClass: "icon-r-magnifier-tool grey100" }),
            _c("InputText", {
              ref: "inputSearch",
              staticClass: "w-100 no-box",
              attrs: { placeholder: _vm.$t("search") },
              on: { input: _vm.searchDebounce },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row pt-4 mb-4" }, [
      _c("div", { staticClass: "col-12 grey300" }, [
        _c("p", [
          _vm._v(
            " Cadastre usuários e senhas de acessos para prefeituras, companhias e outras fontes de documentos. "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }