
import mixins from 'vue-typed-mixins';
import { AccessPass } from '@/resources/access-pass/types';
import AccessPassTable from '@/resources/access-pass/components/tables/AccessPassTable.vue';

export default mixins().extend({
  name: 'ContentSection',

  props: {
    tableRefresh: {
      type: Boolean,
      default: false,
    },
    tableSearch: {
      type: String,
      default: '',
    },
  },

  components: {
    AccessPassTable,
  },

  data: () => ({
    items: [],
  }),

  computed: {
    maskTableRefresh: {
      get(): boolean {
        return this.tableRefresh;
      },
      set(value: boolean) {
        this.$emit('update:tableRefresh', value);
      },
    },
  },

  methods: {
    edit(item: AccessPass) {
      this.$emit('table:edit-item', item);
    },
    loading(bool: boolean) {
      this.$emit('table:loading', bool);
    },
  },
});
