import axios, { CancelTokenSource } from '@/common/http';
import { AccessPass } from '@/resources/access-pass/types';

type Supplier = {
  id: string;
  name: string;
  nationalRegister: string;
};

class AccessPassService {
    private service: typeof axios = axios;

    private cancelTS?: CancelTokenSource;

    private baseUrl = `${process.env.VUE_APP_REBORN_BASE_URL_GATEWAY}/api/v1/access-passes`;
    // private baseUrl = 'http://localhost:8080/api/v1/access-passes';

    private cancelToken(): CancelTokenSource {
      if (this.cancelTS) {
        this.cancelTS.cancel();
      }

      return this.service.CancelToken.source();
    }

    public findAll(search = '') {
      const params = new URLSearchParams();

      if (search) {
        params.set('search', search);
      }

      this.cancelTS = this.cancelToken();

      return this.service.get(this.baseUrl, {
        params,
        cancelToken: this.cancelTS.token,
      });
    }

    public create(payload: AccessPass) {
      return this.service.post(this.baseUrl, payload);
    }

    public update(id: string, payload: AccessPass) {
      return this.service.put(`${this.baseUrl}/${id}`, payload);
    }

    public getSuppliersByFilter(
      { supplierType, stateCode, cityCode }:
      {supplierType: string; stateCode?: number; cityCode?: number},
    ) {
      const params = new URLSearchParams();

      if (supplierType) {
        params.set('type', supplierType);
      }

      if (stateCode) {
        params.set('stateCode', String(stateCode));
      }

      if (cityCode) {
        params.set('cityCode', String(cityCode));
      }

      const url = this.baseUrl;

      return this.service.get<{data: Supplier[]}>(`${url}/suppliers`, { params });
    }

    public async supplierRegistration(supplier: any): Promise<void> {
      await this.service.post('http://localhost:8080/api/v1/access-passes/suppliers-registration', supplier);
    }
}
export default new AccessPassService();
