
import Vue from 'vue';

export default Vue.extend({
  name: 'Field',

  props: {
    title: {
      type: String,
      default: null,
    },

    field: {
      type: String,
      required: true,
    },
  },
});
