var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("AccessPassTable", {
    attrs: { search: _vm.tableSearch, refresh: _vm.maskTableRefresh },
    on: {
      "update:refresh": function ($event) {
        _vm.maskTableRefresh = $event
      },
      "on:edit": function ($event) {
        return _vm.edit($event)
      },
      "on:loading": function ($event) {
        return _vm.loading($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }