var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "PageCard",
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c("HeaderSection", {
            on: {
              "on:show-form-create": _vm.showFormModal,
              "on:search": function ($event) {
                return _vm.search($event)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content" },
        [
          _c("AccessPassFormModal", {
            attrs: {
              "form-payload": _vm.editItem,
              "show-modal": _vm.showModal,
            },
            on: {
              "update:showModal": function ($event) {
                _vm.showModal = $event
              },
              "update:show-modal": function ($event) {
                _vm.showModal = $event
              },
              "form:finished": function ($event) {
                _vm.tableRefresh = true
              },
            },
          }),
          _c("ContentSection", {
            attrs: {
              "table-search": _vm.searchText,
              "table-refresh": _vm.tableRefresh,
            },
            on: {
              "update:tableRefresh": function ($event) {
                _vm.tableRefresh = $event
              },
              "update:table-refresh": function ($event) {
                _vm.tableRefresh = $event
              },
              "table:edit-item": function ($event) {
                return _vm.edit($event)
              },
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }