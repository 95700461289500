var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "mt-1 mb-1" }, [
    _vm.title
      ? _c("span", { staticClass: "color-black mb-6 fs14 font-family" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ])
      : _vm._e(),
    _c(
      "span",
      { staticClass: "color-black my-8 fs14 font-family" },
      [
        _vm._t("left-field"),
        _vm._v(" " + _vm._s(_vm.field) + " "),
        _vm._t("right-field"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }