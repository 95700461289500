var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("PageCard", {
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "px-1 p-mt-3" },
              [
                _c("Header", {
                  attrs: { user: _vm.payload.user, loading: _vm.loading },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "pl-3" },
              [
                _c("PageSection", {
                  staticClass: "mt-4 mb-3",
                  attrs: { title: _vm.$t("managerProfile.myAccount") },
                }),
                _c("div", { staticClass: "grid grid-cols-3 p-nogutter" }, [
                  _c(
                    "div",
                    { staticClass: "col-span-1" },
                    [
                      _c("PersonalInfo", {
                        staticClass: "mt-2 mb-4",
                        attrs: { user: _vm.payload.user, loading: _vm.loading },
                      }),
                      _c("Enterprise", {
                        staticClass: "mt-2",
                        attrs: {
                          enterprise: _vm.payload.company,
                          loading: _vm.loading,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-span-1" },
                    [
                      _c("UserAndPassword", {
                        staticClass: "mt-2",
                        attrs: {
                          account: _vm.payload.user,
                          loading: _vm.loading,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-span-1 text-right" },
                    [
                      !_vm.loading
                        ? _c("Button", {
                            staticClass:
                              "outlined raised sm c-btn mt-2 h-7 px-3 fs12",
                            attrs: {
                              label: _vm.$t("edit"),
                              icon: "icon-r-edit size-icon",
                              "icon-pos": "left",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$router.push({
                                  name: "profile-edit",
                                  params: { profile: _vm.payload },
                                })
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }