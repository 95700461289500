var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-content-between align-items-center" },
    [
      _c("div", { staticClass: "title" }, [
        _c("strong", { staticClass: "fs16" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ]),
      ]),
      _vm._m(0),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-grow-1" }, [
      _c("div", { attrs: { id: "line" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }