
import Vue from 'vue';

import { UserProfile } from '@/types';

export default Vue.extend({
  name: 'UserForm',

  props: {
    value: {
      type: Object as () => UserProfile,
      required: true,
    },
  },

  watch: {
    value: {
      deep: true,
      handler(user: UserProfile) {
        this.$emit('input', user);
      },
    },
  },
});
