
import { FormRef } from '@/types';
import SupplierRegistrationForm from '@/resources/access-pass/components/forms/SupplierRegistrationForm.vue';
import { AccessPass } from '@/resources/access-pass/types';

export default SupplierRegistrationForm.extend({
  name: 'SupplierRegistrationFormModal',
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    formPayload: {
      type: Object as () => AccessPass || null,
      required: false,
    },
  },
  computed: {
    modal: {
      get(): boolean {
        return this.showModal;
      },
      set(value: boolean) {
        this.$emit('update:showModal', value);
      },
    },
    isUpdate(): boolean {
      const { formPayload } = this;
      return formPayload?.id !== undefined;
    },
    title(): string {
      let flag = 'accessPass.modal.create.title';
      if (this.isUpdate) {
        flag = 'accessPass.modal.update.title';
      }
      return this.$t(flag) as string;
    },
  },
  methods: {
    async confirm() {
      const form: any = this.$refs.form as FormRef;
      form.submit();
    },
    formFinished() {
      // empty
    },
  },
});
