
import Vue from 'vue';

import { AddressCommon } from '@/types';
import PosCodeService from '@/common/services/PostCodeService';
import numberRemoveFormat from '@/common/filters/NumberRemoveFormat';

export default Vue.extend({
  name: 'AddressForm',

  props: {
    value: {
      type: Object as () => AddressCommon,
      required: true,
    },

    states: {
      type: Array as () => Array<string>,
      required: true,
    },
  },

  data() {
    return {
      address: {} as AddressCommon,
    };
  },

  watch: {
    address: {
      deep: true,
      handler(address: AddressCommon) {
        this.$emit('input', address);
      },
    },
  },

  created() {
    this.address = { ...this.value };
  },

  methods: {
    async getAddressByZipcode(zipCode: string): Promise<void> {
      try {
        if (zipCode.length === 10) {
          const { data } = await PosCodeService.find(numberRemoveFormat(zipCode));

          if (data?.erro) {
            throw new Error('CEP is not valid');
          }

          this.address.zipCode = zipCode;
          this.address.street = data.logradouro;
          this.address.number = '';
          this.address.additionalDetails = '';
          this.address.neighborhood = data.bairro;
          this.address.city = data.localidade;
          this.address.state = data.uf;
          this.address.country = this.$t('billing.sections.billingInformation.manager.countryName') as string;
        }
      } catch (e) {
        this.address.street = '';
        this.address.number = '';
        this.address.additionalDetails = '';
        this.address.neighborhood = '';
        this.address.city = '';
        this.address.state = '';
        this.address.country = '';
      }
    },
  },
});
