var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("p", { staticClass: "info-text fs14 mt-4 mb-4" }, [
      _vm._v(
        " " + _vm._s(_vm.$t("onboarding.changePassword.passwordHint")) + " "
      ),
    ]),
    _c("div", { staticClass: "grid grid-cols-3" }, [
      _c(
        "div",
        { staticClass: "grid-span-1" },
        [
          _c(
            "Form",
            [
              _c("ChangePassword", {
                staticClass: "max-w-md",
                attrs: {
                  payload: _vm.payload,
                  "use-default-btn-submit": false,
                  response: _vm.response,
                  "save-password": _vm.save,
                },
                on: {
                  "update:savePassword": function ($event) {
                    _vm.save = $event
                  },
                  "update:save-password": function ($event) {
                    _vm.save = $event
                  },
                  "on:submit": function ($event) {
                    return _vm.handleLoading($event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "flex justify-center items-center gap-4 mt-3 mb-3" },
      [
        _c("Button", {
          staticClass: "h-50 outlined",
          attrs: { label: "Cancelar" },
          on: { click: _vm.routeToBack },
        }),
        _c("Button", {
          staticClass: "h-50 px-8",
          attrs: { label: "Salvar" },
          on: { click: _vm.submitChangePassword },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }