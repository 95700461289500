var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "grid pt-4" }, [
      _c(
        "div",
        { staticClass: "col-span-1" },
        [
          _c("UserForm", {
            ref: "user",
            model: {
              value: _vm.profile.user,
              callback: function ($$v) {
                _vm.$set(_vm.profile, "user", $$v)
              },
              expression: "profile.user",
            },
          }),
          _c("AddressForm", {
            ref: "address",
            attrs: { states: _vm.states },
            model: {
              value: _vm.profile.user.address,
              callback: function ($$v) {
                _vm.$set(_vm.profile.user, "address", $$v)
              },
              expression: "profile.user.address",
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "flex justify-end items-center gap-4 mt-3 mb-3" },
      [
        _c("Button", {
          staticClass: "h-50 px-8",
          attrs: { label: "Salvar" },
          on: { click: _vm.updatePersonalProfile },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }