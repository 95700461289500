import axios from '@/common/http';
import { Profile, PersonalProfile, CompanyProfile } from '@/types';

class ProfileService {
  private service: typeof axios = axios;

  private baseUrl = `${process.env.VUE_APP_BASE_URL_GATEWAY}/api/v1/profile`;

  public findProfile() {
    return this.service.get(this.baseUrl);
  }

  public updateProfile(profile: Profile) {
    return this.service.patch(this.baseUrl, profile);
  }

  public updatePersonalProfile(personalProfile: PersonalProfile) {
    const url = `${this.baseUrl}/personal`;
    return this.service.patch(url, personalProfile);
  }

  public updateCompanyProfile(companyProfile: CompanyProfile) {
    const url = `${this.baseUrl}/company`;
    return this.service.patch(url, companyProfile);
  }
}

export default new ProfileService();
