
import mixins from 'vue-typed-mixins';
import { mapMutations } from 'vuex';

import NotificationMixin from '@/mixins/NotificationMixin';
import ChangePassword from '@/common/components/changePassword';
import { Payload } from '@/common/components/changePassword/types';

import { Profile } from '@/types';

export default mixins(NotificationMixin).extend({
  name: 'UserAndPasswordBuildEdit',

  components: {
    ChangePassword,
  },

  props: {
    profile: {
      type: Object as () => Profile,
      required: true,
    },
    routeToBack: {
      type: Function,
      default: () => null,
    },
  },

  data() {
    return {
      payload: {
        userId: this.$store.state?.auth?.user?.id,
      } as Payload,
      save: false,
    };
  },

  watch: {
    save(save) {
      this.handleLoading(save);
    },
  },

  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),

    submitChangePassword() {
      this.save = true;
    },

    async response(success: boolean) {
      this.handleLoading(false);
      if (success) {
        this.successToast({
          text: this.$t('managerProfile.updateProfile.msgSuccess') as string,
        });
        await this.$router.push({ name: 'profile' });
      }
    },
  },
});
