
import mixins from 'vue-typed-mixins';
import { mapMutations, mapGetters } from 'vuex';

import NotificationMixin from '@/mixins/NotificationMixin';
import AddressForm from '@/resources/profile/sections/form/AddressForm.vue';
import UserForm from '@/resources/profile/sections/form/UserForm.vue';

import {
  Profile, PersonalProfile, State,
} from '@/types';
import StatesAndCitiesService from '@/common/services/StatesAndCitiesService';
import ProfileService from '@/common/services/ProfileService';
import numberRemoveFormat from '@/common/filters/NumberRemoveFormat';

export default mixins(NotificationMixin).extend({
  name: 'PersonalBuildEdit',

  components: {
    AddressForm,
    UserForm,
  },

  props: {
    profile: {
      type: Object as () => Profile,
      required: true,
    },
  },

  data() {
    return {
      states: [] as Array<string>,
    };
  },

  computed: {
    ...mapGetters({
      token: 'auth/token',
      userLoggedIn: 'auth/userLoggedIn',
    }),
  },

  watch: {
    token(token: string): void {
      if (token && this.userLoggedIn) {
        this.getStates();
      }
    },
  },

  mounted() {
    if (this.token && this.userLoggedIn) {
      this.getStates();
    }
  },

  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),

    async getStates(): Promise<void> {
      try {
        this.handleLoading(true);

        this.states = (
          await StatesAndCitiesService.getStates()
        ).data.data.map((state: State) => state.uf);
      } catch (e) {
        this.errorToast({
          text: this.$t('errorStates') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },

    async invalidData(): Promise<boolean> {
      const valid = new Array<boolean>();

      valid.push(await (this.$refs.user as any).$children[0].validate());

      valid.push(await (this.$refs.address as any).$children[0].validate());

      return valid.includes(false);
    },

    prepareData(): PersonalProfile {
      return {
        ...this.profile.user,
        phoneNumber: numberRemoveFormat(this.profile.user.phoneNumber),
        residentialPhoneNumber: numberRemoveFormat(this.profile.user.residentialPhoneNumber),
        address: {
          ...this.profile.user.address,
          zipCode: numberRemoveFormat(this.profile.user.address.zipCode),
        },
      };
    },

    async updatePersonalProfile(): Promise<void> {
      try {
        if (await this.invalidData()) {
          this.errorToast({
            title: this.$t('validation') as string,
            text: this.$t('managerProfile.updateProfile.msgValidation') as string,
          }, {
            life: 5000,
          });

          return;
        }

        this.handleLoading(true);

        await ProfileService.updatePersonalProfile(this.prepareData());

        this.successToast({
          text: this.$t('managerProfile.updateProfile.msgSuccess') as string,
        });

        await this.$router.push({ name: 'profile' });
      } catch (e) {
        this.errorToast({
          text: this.$t('managerProfile.updateProfile.msgError') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },

  },
});
