
import Vue from 'vue';
import { showDateToUser } from '@roit/roit-date';
import Field from '@/resources/profile/components/Field.vue';
import TitleComponent from '@/resources/profile/components/TitleComponent.vue';

import CepFormat from '@/common/filters/CepFormat';
import PhoneFormat from '@/common/filters/PhoneFormat';

import { UserProfile } from '@/types';

export default Vue.extend({
  name: 'PersonalInfo',

  components: {
    Field,
    TitleComponent,
  },

  filters: {
    CepFormat,
    PhoneFormat,
  },

  props: {
    user: {
      type: Object as () => UserProfile,
      required: true,
    },

    loading: {
      type: Boolean as () => boolean,
      default: false,
    },
  },

  computed: {
    fullStreet(): string {
      const {
        street, number, additionalDetails, neighborhood,
      } = this.user.address;
      let fullStreet = '';

      fullStreet = `${street}, ${number}`;

      if (additionalDetails) {
        fullStreet = fullStreet.concat(`, ${additionalDetails}`);
      }

      return fullStreet.concat(` - ${neighborhood}`);
    },
    fullStreetComplement(): string {
      const {
        zipCode, city, state, country,
      } = this.user.address;

      return `${zipCode} - ${city}/${state} - ${country}`;
    },
  },

  methods: {
    showISODate(date: string): string {
      const formated = showDateToUser(date);
      if (!formated) return date;
      return formated;
    },
  },
});

