var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("strong", { staticClass: "color-grey-dark font-family fs16" }, [
      _vm._v(" " + _vm._s(_vm.title) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }