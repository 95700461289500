
import mixins from 'vue-typed-mixins';
import Table from '@/common/components/table/Table.vue';
import { HeaderCustomTable } from '@/types';
import { AccessPass } from '@/resources/access-pass/types';
import AccessPassService from '@/common/services/AccessPassService';
import NotificationMixin from '@/mixins/NotificationMixin';
import locales from '../../locales';

export default mixins(NotificationMixin).extend({
  name: 'AccessTableTable',

  i18n: {
    messages: locales,
  },

  components: {
    CustomTable: Table,
  },

  props: {
    search: {
      type: String,
      required: false,
      default: '',
    },

    refresh: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false as boolean,
      rows: [] as AccessPass[],
      header: {
        alias: [
          this.$t('accessPass.mainTable.establishment'),
          this.$t('accessPass.mainTable.supplierType'),
          this.$t('accessPass.mainTable.supplier'),
          this.$t('accessPass.mainTable.username'),
        ],
        columnsToShow: [
          'establishment',
          'supplierType',
          'supplier',
          'username',
        ],
      } as HeaderCustomTable,
    };
  },

  computed: {
    rowsData(): Array<AccessPass> {
      return this.rows;
    },
  },

  mounted() {
    this.accessPassesFind(this.search);
  },

  methods: {
    edit(item: AccessPass): void {
      this.$emit('on:edit', item);
    },

    async accessPassesFind(search: string) {
      try {
        this.loading = true;
        const { data } = await AccessPassService.findAll(search);
        if ('data' in data) {
          this.rows = data.data;
        }
      } catch {
        this.warningToast({
          text: 'Falha na requisição',
        });
      }
      this.loading = false;
    },

    mapSupplierUnit(value: string): string {
      const data: {[char: string]: string } = {
        KILOWATT_HOUR: 'accessPass.adapters.supplierUnit.kilowattHour',
        CUBIC_METER: 'accessPass.adapters.supplierUnit.cubicMeter',
        MINUTE: 'accessPass.adapters.supplierUnit.minute',
        N_D: 'accessPass.adapters.supplierUnit.nd',
      };

      return this.$t(data[value]) as string;
    },

    mapSupplierType(value: string): string {
      const data: {[char: string]: string } = {
        WATER: 'accessPass.adapters.supplierType.water',
        ELECTRICAL_ENERGY: 'accessPass.adapters.supplierType.electricalEnergy',
        GAS: 'accessPass.adapters.supplierType.gas',
        CITY_HALL: 'accessPass.adapters.supplierType.cityHall',
        TELECOMMUNICATION: 'accessPass.adapters.supplierType.telecommunication',
        N_D: 'accessPass.adapters.supplierType.nd',
      };

      return this.$t(data[value]) as string;
    },
  },

  watch: {
    async search(search) {
      await this.accessPassesFind(search);
    },
    async refresh(refresh, oldRefresh) {
      if (refresh && !oldRefresh) {
        await this.accessPassesFind(this.search);
        this.$emit('update:refresh', false);
      }
    },
  },
});
