var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("TitleComponent", {
        staticClass: "mb-2",
        attrs: { title: _vm.$t("managerProfile.personalInformations") },
      }),
      _vm.loading
        ? _c(
            "div",
            [
              _c("b-skeleton", { attrs: { width: "30%" } }),
              _c(
                "div",
                { staticClass: "p-grid p-nogutter" },
                [
                  _c("b-skeleton", {
                    staticClass: "p-mr-3",
                    attrs: { width: "15%" },
                  }),
                  _c("b-skeleton", { attrs: { width: "10%" } }),
                ],
                1
              ),
              _c("b-skeleton", { attrs: { width: "20%" } }),
              _c(
                "div",
                { staticClass: "p-grid p-nogutter" },
                [
                  _c("b-skeleton", {
                    staticClass: "p-mr-3",
                    attrs: { width: "13%" },
                  }),
                  _c("b-skeleton", { attrs: { width: "13%" } }),
                ],
                1
              ),
              _c("b-skeleton", { attrs: { width: "30%" } }),
              _c("b-skeleton", { attrs: { width: "30%" } }),
            ],
            1
          )
        : _c(
            "div",
            [
              _vm.user.fullName
                ? _c("Field", { attrs: { field: _vm.user.fullName } })
                : _vm._e(),
              _vm.user.email
                ? _c("Field", { attrs: { field: _vm.user.email } })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "grid grid-cols-2 p-nogutter" },
                [
                  _vm.user.phoneNumber
                    ? _c("Field", {
                        staticClass: "col-span-1",
                        attrs: {
                          field: _vm._f("PhoneFormat")(_vm.user.phoneNumber),
                        },
                      })
                    : _vm._e(),
                  _vm.user.residentialPhoneNumber
                    ? _c("Field", {
                        staticClass: "col-span-1",
                        attrs: {
                          field: _vm._f("PhoneFormat")(
                            _vm.user.residentialPhoneNumber
                          ),
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.user.address.street
                ? _c("Field", { attrs: { field: _vm.fullStreet } })
                : _vm._e(),
              _vm.user.address.zipCode
                ? _c("Field", {
                    attrs: {
                      title: _vm.$t(
                        "managerProfile.fields.address.zipcode.label"
                      ),
                      field: _vm._f("CepFormat")(_vm.fullStreetComplement),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }