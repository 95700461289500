
import Vue from 'vue';

import Cover from '@/resources/profile/components/Cover.vue';
import Avatar from '@/resources/profile/components/Avatar.vue';

import { UserProfile } from '@/types';

export default Vue.extend({
  name: 'Header',

  components: {
    Cover,
    Avatar,
  },

  props: {
    user: {
      type: Object as () => UserProfile,
      required: true,
    },

    avatarEdit: {
      type: Boolean,
    },

    coverEdit: {
      type: Boolean,
    },

    loading: {
      type: Boolean as () => boolean,
      default: false,
    },
  },

  watch: {
    user: {
      handler() { return null; },
      deep: true,
    },
  },

  methods: {
    onCoverUrlChange(coverUrl: string) {
      this.user.backgroundPhotoUrl = coverUrl;
    },

    onPhotoUrlChange(photoUrl: string) {
      this.user.photoUrl = photoUrl;
    },
  },
});
