
import mixins from 'vue-typed-mixins';
import PageCard from '@/common/template/PageCard.vue';
import { Profile } from '@/types';
import Header from '@/resources/profile/sections/Header.vue';

export default mixins().extend({
  name: 'ProfileTabMenu',
  components: {
    PageCard,
    Header,
  },
  props: {
    profile: {
      type: Object as () => Profile,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      items: [
        {
          label: this.$t('managerProfile.tabMenu.titles.tabOne'),
          to: {
            name: 'tab-one',
            params: { profile: this.$props.profile, routeToBack: () => this.$emit('toBack') },
          },
        },
        {
          label: this.$t('managerProfile.tabMenu.titles.tabTwo'),
          to: {
            name: 'tab-two',
            params: {
              profile: this.$props.profile,
              routeToBack: () => this.$emit('toBack'),
            },
          },
        },
      ],
    };
  },

  mounted() {
    this.$on('toBack', this.returnToProfile);
  },

  methods: {
    returnToProfile() {
      this.$router.push({ name: 'profile' });
    },
  },
});
