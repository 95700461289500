
export default {
  name: 'PageSection',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
