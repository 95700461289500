var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "header",
    [
      _c("Cover", {
        staticClass: "cover-container",
        attrs: {
          "show-change-cover-button": true,
          "cover-uri": _vm.user.backgroundPhotoUrl,
          loading: _vm.loading,
        },
        on: { "update:cover-url": _vm.onCoverUrlChange },
      }),
      _c(
        "div",
        { staticClass: "p-d-flex data-container" },
        [
          _c("Avatar", {
            staticClass: "avatar-position ml-8",
            attrs: {
              "show-toogle-menu": _vm.avatarEdit,
              "photo-url": _vm.user.photoUrl,
              loading: _vm.loading,
            },
            on: { "update:photo-url": _vm.onPhotoUrlChange },
          }),
          _vm.loading
            ? _c(
                "div",
                { staticClass: "p-ml-2 p-mt-2" },
                [
                  _c("b-skeleton", { attrs: { width: "8000%" } }),
                  _c("b-skeleton", { attrs: { width: "7000%" } }),
                ],
                1
              )
            : _c("div", { staticClass: "p-ml-3 p-mt-2" }, [
                _c("h3", { staticClass: "fs16 font-weight-bold title" }, [
                  _vm._v(" " + _vm._s(_vm.user.fullName) + " "),
                ]),
                _c("h5", { staticClass: "fs14 subtitle" }, [
                  _vm._v(" " + _vm._s(_vm.user.job) + " "),
                ]),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }