
import mixins from 'vue-typed-mixins';
import { mapMutations } from 'vuex';

import {
  VForm, State, City,
} from '@/types';
import NotificationMixin from '@/mixins/NotificationMixin';
import StatesAndCitiesService from '@/common/services/StatesAndCitiesService';
import AccessPassService from '@/common/services/AccessPassService';

export default mixins(NotificationMixin).extend({
  name: 'SupplierRegistrationForm',
  data() {
    return {
      states: [] as State[],
      cities: [] as City[],
      formData: {
        name: null,
        homePage: '',
        supplierType: '',
        state: null as null | State & {
                    ibgeCode: number;
                },
        city: null as null | City & {
                    ibgeCode: number;
                },
        isNationalCoverage: false,
      },
    };
  },
  async mounted() {
    try {
      this.handleLoading(true);
      const promises = [
        this.loadStates(),
        this.loadCitiesByState(),
      ];
      await Promise.all(promises);
    } catch {
      this.failRequest();
    }
    this.handleLoading(false);
  },
  computed: {
    supplierTypes() {
      return [
        {
          optionLabel: 'Água',
          optionValue: 'WATER',
        },
        {
          optionLabel: 'Energia',
          optionValue: 'ELECTRICAL_ENERGY',
        },
        {
          optionLabel: 'Gás',
          optionValue: 'GAS',
        },
        {
          optionLabel: 'Prefeitura',
          optionValue: 'CITY_HALL',
        },
        {
          optionLabel: 'Telecomunicação',
          optionValue: 'TELECOMMUNICATION',
        },
      ];
    },
    statesUf(): Array<string> {
      return this.states.map((state: State) => state.uf);
    },
    stateCode(): number | null {
      if (this.formData.state && 'ibgeCode' in this.formData.state) {
        return this.formData.state.ibgeCode;
      }
      return null;
    },
  },
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),
    failRequest() {
      this.warningToast({
        text: this.$t('accessPass.fail.request.message') as string,
      });
    },
    async submit() {
      const form: any = this.$refs.form as VForm;
      const valid: boolean = await form.validate();
      if (valid) {
        await this.create(this.formData);
      }
    },
    async create(formData: any) {
      try {
        this.handleLoading(true);
        await AccessPassService.supplierRegistration(formData);
      } catch (error) {
        this.failRequest();
      }
      this.handleLoading(false);
    },
    async loadStates(): Promise<void> {
      const { data: response } = await StatesAndCitiesService.getStates();
      if ('data' in response && response.data) {
        this.states = response.data as State[];
      }
    },
    async loadCitiesByState(): Promise<void> {
      const { stateCode } = this;
      if (stateCode) {
        const { data: response } = await StatesAndCitiesService.getStateCities(stateCode);
        if ('data' in response && response.data) {
          this.cities = response.data as City[];
        }
        return;
      }
      this.cities = [];
    },
    async citiesByStateHandler(): Promise<void> {
      this.handleLoading(true);
      await this.loadCitiesByState();
      this.handleLoading(false);
    },
    stateOptionValue(e: {
            code: number;
            uf: string;
            name: string;
        }) {
      if (!e) {
        return null;
      }
      return {
        ibgeCode: e.code,
        uf: e.uf,
        name: e.name,
      };
    },
    cityOptionValue(e: {
            optionValue: string;
            codeIBGE: number;
            name: string;
        }) {
      if (!e) {
        return null;
      }
      return {
        ibgeCode: e.codeIBGE,
        name: e.name,
      };
    },
    stateChangeHandler() {
      this.citiesByStateHandler();
    },
  },
});
