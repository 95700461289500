var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "el-dropdown",
          { attrs: { trigger: "click" } },
          [
            _c(
              "Button",
              {
                staticClass:
                  "p-button-raised p-button-rounded p-shadow-4 button",
                staticStyle: {},
                attrs: { icon: "pi pi-camera" },
              },
              [_c("i", { staticClass: "icon-r-photo-camera" })]
            ),
            _c(
              "el-dropdown-menu",
              _vm._l(_vm.items, function (item, key) {
                return _c(
                  "el-dropdown-item",
                  {
                    key: key,
                    staticClass: "text-center",
                    nativeOn: {
                      click: function ($event) {
                        return item.command.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "mr-2",
                      attrs: {
                        src: item.icon,
                        width: item.width,
                        height: item.height,
                      },
                    }),
                    _c("span", { staticClass: "label" }, [
                      _vm._v(_vm._s(item.label)),
                    ]),
                  ]
                )
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("form", { ref: "form" }, [
      _c("input", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        ref: "fileAvatar",
        attrs: { type: "file", accept: "image/png, image/jpeg" },
        on: { change: _vm.onFileChange },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }