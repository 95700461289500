
import Vue from 'vue';
import Field from '@/resources/profile/components/Field.vue';
import TitleComponent from '@/resources/profile/components/TitleComponent.vue';

export default Vue.extend({
  name: 'UserCredentials',
  components: {
    Field,
    TitleComponent,
  },
  props: {
    user: {
      type: String,
      required: true,
    },
    password: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },

});

