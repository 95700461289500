var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Dialog",
    {
      style: { width: "600px" },
      attrs: {
        visible: _vm.modal,
        header: "Registro de Fornecedores",
        modal: true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.modal = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("Button", {
                staticClass: "outlined p-button-raised",
                attrs: { id: "cancelButton", label: _vm.$t("cancel") },
                on: {
                  click: function ($event) {
                    _vm.modal = false
                  },
                },
              }),
              _c("Button", {
                staticClass: "raised next-step",
                attrs: {
                  id: "registerButton",
                  label: _vm.$t(`${_vm.isUpdate ? "update" : "register"}`),
                },
                on: {
                  click: function ($event) {
                    return _vm.confirm()
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "py-3.5" },
        [
          _c("p", [
            _vm._v(
              " Esse código deve ser utilizando quando for necessario cadastrar novos fornecedores. Esse código não deve ser exposto ao cliente. "
            ),
          ]),
          _c("SupplierRegistrationForm", {
            ref: "form",
            attrs: { autoload: _vm.showModal },
            on: { finished: _vm.formFinished },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }