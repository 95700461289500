
import Vue from 'vue';
import TitleComponent from '@/resources/profile/components/TitleComponent.vue';
import Field from '@/resources/profile/components/Field.vue';

// import CnpjFormat from '@/common/filters/CnpjFormat';
// import CepFormat from '@/common/filters/CepFormat';

import { UserProfile } from '@/types';

export default Vue.extend({
  name: 'UserAndPasswordForm',

  components: {
    TitleComponent,
    Field,
  },

  props: {
    account: {
      type: Object as () => UserProfile,
      required: true,
    },

    loading: {
      type: Boolean as () => boolean,
      default: false,
    },
  },
});
