var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "p-d-flex" }, [
    _c(
      "div",
      { staticClass: "p-shadow-4 p-p-1 avatar-container" },
      [
        _vm.loading
          ? _c("b-skeleton", {
              staticClass: "profile-pic avatar",
              attrs: { type: "avatar" },
            })
          : _c("img", {
              staticClass: "profile-pic avatar",
              attrs: {
                src:
                  _vm.url || require("@/common/assets/images/use-avatar.svg"),
              },
            }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showToogleMenu,
            expression: "showToogleMenu",
          },
        ],
        staticStyle: { "z-index": "2" },
      },
      [
        _c("ToggleMenu", {
          staticClass: "p-d-inline-flex toggle-menu-position",
          on: { onChangeUri: _vm.changeAvatarUri },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }