import { LocaleMessages } from 'vue-i18n';

const locales: LocaleMessages = {
  'pt-br': {
    accessPass: {
      modal: {
        create: {
          title: 'Cadastrar Senhas de Acessos',
        },
        update: {
          title: 'Atualizar Senhas de Acessos',
        },
      },
      form: {
        establishment: {
          label: 'Empresa',
          placeholder: 'Selecione',
        },
        supplierType: {
          label: 'Tipo de Fornecedor/Órgão',
          placeholder: 'Ex.: Prefeitura',
        },
        supplierUnit: {
          label: 'Unidade de Medida',
          placeholder: 'Selecione',
        },
        supplier: {
          label: 'Fornecedor/Órgão',
          placeholder: 'Nome do Fornecedor/Órgão',
          selectEmptyByType: {
            waterNotRegistered: 'Companhia de Água não cadastrada',
            electricalEnergyNotRegistered: 'Companhia de Energia Elétrica não cadastrada',
            gasNotRegistered: 'Companhia de Gás não cadastrada',
            cityHallNotRegistered: 'Prefeitura não cadastrada',
            telecommunicationNotRegistered: 'Provedor de Telecomunicação não cadastrado',
            providerNotRegistered: 'Fornecedor/Órgão não cadastrado',
          },
        },
        username: {
          label: 'Usuário',
          placeholder: 'Informe o Usuário',
        },
        password: {
          label: 'Senha',
          placeholder: 'Informe a Senha',
        },
        email: {
          label: 'E-mail',
          placeholder: 'Digite o e-mail',
        },
        accessCode: {
          label: 'Código de Acesso',
          placeholder: 'Digite o código',
        },
        state: {
          label: 'Estado',
          placeholder: 'Selecione',
        },
        city: {
          label: 'Cidade',
          placeholder: 'Selecione',
        },
        expirationDay: {
          label: 'Dia de Vencimento',
        },
        availabilityDay: {
          label: 'Dia de Disponibilização',
        },
        telecommunicationProduct: {
          label: 'Produto',
          placeholder: 'Selecione',
        },
        consumer: {
          label: 'Unidade Consumidora',
          placeholder: 'Digite',
        },
      },
      adapters: {
        supplierUnit: {
          kilowattHour: 'kWh',
          cubicMeter: 'M³',
          minute: 'Minutos',
          nd: 'Não aplicável',
        },
        supplierType: {
          water: 'Água',
          electricalEnergy: 'Energia',
          gas: 'Gás',
          cityHall: 'Prefeitura',
          telecommunication: 'Telecomunicação',
          nd: 'Não aplicável',
        },
      },
      success: {
        create: {
          message: 'Senha de acesso cadastrada com sucesso!',
        },
        update: {
          message: 'Senha de acesso atualizada com sucesso!',
        },
      },
      fail: {
        request: {
          message: 'Falha na requisição',
        },
      },
      mainTable: {
        establishment: 'Empresa',
        supplierType: 'Tipo de Fornecedor/Órgão',
        supplierUnit: 'Unidade',
        supplier: 'Fornecedor/Órgão',
        username: 'Usuário',
        password: 'Senha',
        email: 'E-mail',
        accessCode: 'Código de Acesso',
        state: 'Estado',
        city: 'Cidade',
      },

    },
  },
  en: {
    accessPass: {
      modal: {
        create: {
          title: 'Register Access Passwords',
        },
        update: {
          title: 'Update Access Passwords',
        },
      },
      form: {
        establishment: {
          label: 'Company',
          placeholder: 'Select',
        },
        supplierType: {
          label: 'Type of Supplier/Agency',
          placeholder: 'Ex.: City Hall',
        },
        supplierUnit: {
          label: 'Unit of Measurement',
          placeholder: 'Select',
        },
        supplier: {
          label: 'Supplier/Organ',
          placeholder: 'Supplier/Agency Name',
          selectEmptyByType: {
            waterNotRegistered: 'Water company not registered',
            electricalEnergyNotRegistered: 'Electric Power company not registered',
            gasNotRegistered: 'Gas company not registered',
            cityHallNotRegistered: 'City hall not registered',
            telecommunicationNotRegistered: 'Telecommunication Provider not registered',
            providerNotRegistered: 'Provider not registered',
          },
        },
        username: {
          label: 'User',
          placeholder: 'Inform the User',
        },
        password: {
          label: 'Password',
          placeholder: 'Enter Password',
        },
        email: {
          label: 'Email',
          placeholder: 'Enter email',
        },
        accessCode: {
          label: 'Access Code',
          placeholder: 'Enter code',
        },
        state: {
          label: 'State',
          placeholder: 'Select',
        },
        city: {
          label: 'City',
          placeholder: 'Select',
        },
        expirationDay: {
          label: 'Expiration Day',
        },
        availabilityDay: {
          label: 'Availability Day',
        },
        telecommunicationProduct: {
          label: 'Product',
          placeholder: 'Select',
        },
        consumer: {
          label: 'Unit Consumer',
          placeholder: 'Type it',
        },
      },
      adapters: {
        supplierUnit: {
          kilowattHour: 'kWh',
          cubicMeter: 'M³',
          minute: 'Minutes',
          na: 'Not applicable',
        },
      },
      success: {
        create: {
          message: 'Access password successfully registered!',
        },
        update: {
          message: 'Access password updated successfully!',
        },
      },
      fail: {
        request: {
          message: 'Request failed',
        },
      },
    },
  },
  es: {
    accessPass: {
      modal: {
        create: {
          title: 'Registrar contraseñas de acceso',
        },
        update: {
          title: 'Actualizar contraseñas de acceso',
        },
      },
      form: {
        establishment: {
          label: 'Compañía',
          placeholder: 'Seleccionar',
        },
        supplierType: {
          label: 'Tipo de proveedor / agencia',
          placeholder: 'Ej .: Ayuntamiento',
        },
        supplierUnit: {
          label: 'Unidad de Medida',
          placeholder: 'Seleccionar',
        },
        supplier: {
          label: 'Proveedor / órgano',
          placeholder: 'Nombre del proveedor / órgano',
          selectEmptyByType: {
            waterNotRegistered: 'Empresa de agua no registrada',
            electricalEnergyNotRegistered: 'Empresa de energía eléctrica no registrada',
            gasNotRegistered: 'Compañía de gas no registrada',
            cityHallNotRegistered: 'Ayuntamiento no registrado',
            telecommunicationNotRegistered: 'Proveedor de telecomunicaciones no registrado',
            providerNotRegistered: 'Proveedor no registrado',
          },
        },
        username: {
          label: 'Usuario',
          placeholder: 'Informar al usuario',
        },
        password: {
          label: 'Contraseña',
          placeholder: 'Ingresar contraseña',
        },
        email: {
          label: 'Correo electrónico',
          placeholder: 'Ingresar correo electrónico',
        },
        accessCode: {
          label: 'Código de acceso',
          placeholder: 'Ingresar código',
        },
        state: {
          label: 'Estado',
          placeholder: 'Seleccionar',
        },
        city: {
          label: 'Ciudad',
          placeholder: 'Seleccionar',
        },
        expirationDay: {
          label: 'Día de caducidad',
        },
        availabilityDay: {
          label: 'Día de disponibilidad',
        },
        telecommunicationProduct: {
          label: 'Producto',
          placeholder: 'Seleccionar',
        },
        consumer: {
          label: 'Unidad de Consumo',
          placeholder: 'Escribelo',
        },
      },
      adapters: {
        supplierUnit: {
          kilowattHour: 'kWh',
          cubicMeter: 'M³',
          minute: 'Minutos',
          na: 'No aplicable',
        },
      },
      success: {
        create: {
          message: 'Contraseña de acceso registrada correctamente',
        },
        update: {
          message: 'Contraseña de acceso actualizada correctamente',
        },
      },
      fail: {
        request: {
          message: 'Solicitud fallida',
        },
      },
    },
  },
};

export default locales;
