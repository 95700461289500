var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("TitleComponent", {
        staticClass: "mb-2",
        attrs: { title: _vm.$t("managerProfile.fields.account.title") },
      }),
      _vm.loading
        ? _c(
            "div",
            [
              _c("b-skeleton", { attrs: { width: "25%" } }),
              _c("b-skeleton", { attrs: { width: "25%" } }),
            ],
            1
          )
        : _c(
            "div",
            [
              _vm.account.login
                ? _c("Field", { attrs: { field: _vm.account.login } })
                : _vm._e(),
              _c("Field", {
                attrs: { field: "**************" },
                scopedSlots: _vm._u([
                  {
                    key: "right-field",
                    fn: function () {
                      return [
                        _c("i", { staticClass: "icon-r-padlock-password" }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }