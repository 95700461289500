import {
  AddressCommon, CompanyProfile, Password, Profile, UserProfile,
} from '@/types';

export const emptyAddress: AddressCommon = {
  street: '',
  number: '',
  additionalDetails: '',
  neighborhood: '',
  city: '',
  state: '',
  country: '',
  zipCode: '',
};

export const emptyUserPassword: Password = {
  oldPassword: '',
  newPassword: '',
};

export const emptyUser: UserProfile = {
  firstName: '',
  lastName: '',
  fullName: '',
  email: '',
  photoUrl: '',
  backgroundPhotoUrl: '',
  phoneNumber: '',
  residentialPhoneNumber: '',
  job: '',
  address: emptyAddress,
  credentials: emptyUserPassword,
};

export const emptyCompany: CompanyProfile = {
  id: '',
  nationalRegister: '',
  companyGroup: '',
  statusReceitaFederal: '',
  master: false,
  hubSpotId: '',
  stateRegistration: '',
  cnpj: '',
  city: '',
  name: '',
  userId: '',
  state: '',
  address: emptyAddress,
  municipalRegistration: '',
  taxRegime: {
    type: '',
    assessment: '',
  },
  idErp: '',
  parentCnpj: '',
  companyId: '',
  taxGuide: '',
  tradeName: '',
};

export const emptyProfile: Profile = {
  user: emptyUser,
  company: emptyCompany,
};
