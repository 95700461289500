
import mixins from 'vue-typed-mixins';
import { mapMutations } from 'vuex';
import { Establishment } from '@roit-intern/roit-company-interfaces';

import {
  VForm, State, City, SelectOption,
} from '@/types';
import NotificationMixin from '@/mixins/NotificationMixin';
import { AccessPass } from '@/resources/access-pass/types';
import locales from '@/resources/access-pass/locales';
import InputPassword from '@/common/components/input-password/InputPassword.vue';
import StatesAndCitiesService from '@/common/services/StatesAndCitiesService';
import AccessPassService from '@/common/services/AccessPassService';
import EstablishmentService from '@/common/services/EstablishmentService';

type EstablishmentSelectOptions = Establishment[] & SelectOption[]

export default mixins(NotificationMixin).extend({
  name: 'AccessPassForm',

  props: {
    payload: {
      type: Object as () => AccessPass || null,
      required: false,
    },
    autoload: {
      type: Boolean,
      default: false,
    },
  },

  i18n: {
    messages: locales,
  },

  components: { InputPassword },

  data() {
    return {
      isDisabled: false,
      loading: true as boolean,
      states: [] as State[],
      cities: [] as City[],
      establishments: [] as EstablishmentSelectOptions,
      suppliers: [] as SelectOption[],
      formData: {
        establishment: null,
        supplierType: '',
        supplier: null,
        username: '',
        password: '',
        state: null,
        city: null,
        consumer: '',
        telecommunicationProduct: '',
      } as AccessPass,
      pickedOptions: {
        isCityHallPicked: false,
        isWaterPicked: false,
        isEletricalEnergyPicked: false,
        isGasPicked: false,
        isTelecommunicationPicked: false,
      },
      initLoading: true,
      cityFieldDisabled: false,
      multipleLoading: [] as string[],
    };
  },

  async mounted() {
    this.$on('submit', this.submit);
    if (this.payload) {
      this.formData = { ...this.payload };
    }

    if (this.autoload) {
      try {
        this.handleLoading(true);
        this.initLoading = true;
        const promises = [
          this.loadEstablishments(),
          this.loadStates(),
        ];
        if (this.isUpdate) {
          promises.push(this.loadCitiesByState());
          promises.push(this.loadSuppliers());
        }
        await Promise.all(promises);
      } catch {
        this.failRequest();
      }
      this.initLoading = false;
      this.handleLoading(false);
    }
  },

  computed: {
    usernameFieldDisabled(): boolean {
      const {
        isCityHallPicked,
        isWaterPicked,
        isEletricalEnergyPicked,
        isGasPicked,
        isTelecommunicationPicked,
      } = this.pickedOptions;

      return ![
        isCityHallPicked,
        isWaterPicked,
        isEletricalEnergyPicked,
        isGasPicked,
        isTelecommunicationPicked,
      ].includes(true);
    },
    passwordFieldDisabled(): boolean {
      const {
        isCityHallPicked,
        isWaterPicked,
        isEletricalEnergyPicked,
        isGasPicked,
        isTelecommunicationPicked,
      } = this.pickedOptions;

      return ![
        isCityHallPicked,
        isWaterPicked,
        isEletricalEnergyPicked,
        isGasPicked,
        isTelecommunicationPicked,
      ].includes(true);
    },
    consumerFieldDisabled(): boolean {
      const {
        isWaterPicked,
        isEletricalEnergyPicked,
        isGasPicked,
        isTelecommunicationPicked,
      } = this.pickedOptions;

      return ![
        isWaterPicked,
        isEletricalEnergyPicked,
        isGasPicked,
        isTelecommunicationPicked,
      ].includes(true);
    },
    stateFieldDisabled(): boolean {
      const { isCityHallPicked } = this.pickedOptions;

      return ![isCityHallPicked].includes(true);
    },
    telecommunicationProductFieldDisabled(): boolean {
      const { isTelecommunicationPicked } = this.pickedOptions;

      return ![isTelecommunicationPicked].includes(true);
    },
    telecommunicationProducts() {
      // Fixo, Móvel, Internet.
      return [
        {
          optionLabel: 'Fixo',
          optionValue: 'LANDLINE',
        },
        {
          optionLabel: 'Móvel',
          optionValue: 'MOBILE',
        },
        {
          optionLabel: 'Internet',
          optionValue: 'INTERNET',
        },
      ];
    },
    // payload: {
    //   get(): AccessPass {
    //     return this.value;
    //   },
    //   set(value: AccessPass) {
    //     this.$emit('input', value);
    //   },
    // },
    supplierTypes() {
      return [
        {
          optionLabel: 'Água',
          optionValue: 'WATER',
        },
        {
          optionLabel: 'Energia',
          optionValue: 'ELECTRICAL_ENERGY',
        },
        {
          optionLabel: 'Gás',
          optionValue: 'GAS',
        },
        {
          optionLabel: 'Prefeitura',
          optionValue: 'CITY_HALL',
        },
        {
          optionLabel: 'Telecomunicação',
          optionValue: 'TELECOMMUNICATION',
        },
      ];
    },
    locale(): string {
      return this.$store.state.locale;
    },
    isUpdate(): boolean {
      return !!this.payload?.id;
    },

    statesUf(): Array<string> {
      return this.states.map((state: State) => state.uf);
    },
    citiesName(): Array<string> {
      return this.cities.map((city: City) => city.name);
    },
    stateCode(): number | null {
      if (this.formData.state && 'ibgeCode' in this.formData.state) {
        return this.formData.state.ibgeCode;
      }
      return null;
    },
    isSuppliersEmpty(): boolean {
      return this.suppliers.length === 0;
    },
    suppliersOrEmpty(): SelectOption[] {
      if (this.isSuppliersEmpty) {
        let messageEmptyProp = 'accessPass.form.supplier.selectEmptyByType.';

        switch (this.formData.supplierType) {
          case 'WATER':
            messageEmptyProp += 'waterNotRegistered';
            break;
          case 'ELECTRICAL_ENERGY':
            messageEmptyProp += 'electricalEnergyNotRegistered';
            break;
          case 'GAS':
            messageEmptyProp += 'gasNotRegistered';
            break;
          case 'CITY_HALL':
            messageEmptyProp += 'cityHallNotRegistered';
            break;
          case 'TELECOMMUNICATION':
            messageEmptyProp += 'telecommunicationNotRegistered';
            break;
          default:
            messageEmptyProp += 'providerNotRegistered';
            break;
        }

        return [{
          optionLabel: this.$t(messageEmptyProp) as string,
          optionValue: '',
          name: this.$t(messageEmptyProp) as string,
          optionDisabled: true,
        }];
      }
      return this.suppliers;
    },
  },

  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),

    failRequest() {
      this.warningToast({
        text: this.$t('accessPass.fail.request.message') as string,
      });
    },

    async submit() {
      const form: any = this.$refs.form as VForm;
      const valid: boolean = await form.validate();
      if (valid) {
        if (this.formData && this.payload?.id) {
          await this.update(this.payload.id, this.formData);
        } else {
          await this.create(this.formData);
        }
        this.$emit('finished');
      }
    },

    async create(formData: AccessPass) {
      try {
        this.handleLoading(true);

        await AccessPassService.create(formData);

        this.successToast({
          text: this.$t('accessPass.success.create.message') as string,
        });
      } catch (error) {
        this.failRequest();
      }
      this.handleLoading(false);
    },

    async update(id: string, formData: AccessPass) {
      try {
        this.handleLoading(true);

        await AccessPassService.update(id, formData);

        this.successToast({
          text: this.$t('accessPass.success.update.message') as string,
        });
      } catch (error) {
        this.failRequest();
      }
      this.handleLoading(false);
    },

    async loadStates(): Promise<void> {
      const { data: response } = await StatesAndCitiesService.getStates();
      if ('data' in response && response.data) {
        this.states = response.data as State[];
      }
    },

    async loadCitiesByState(): Promise<void> {
      const { stateCode } = this;
      if (stateCode) {
        const { data: response } = await StatesAndCitiesService.getStateCities(stateCode);
        if ('data' in response && response.data) {
          this.cities = response.data as City[];
        }
        return;
      }
      this.cities = [];
    },

    async loadEstablishments() {
      const result = await EstablishmentService.selectOptions();
      try {
        this.establishments = result || [];
      } catch (error) {
        console.log(error);
      }
    },

    async loadSuppliers() {
      const { formData } = this;
      if (formData.supplierType) {
        try {
          const { data: response } = await AccessPassService
            .getSuppliersByFilter({
              supplierType: formData.supplierType,
              stateCode: formData.state?.ibgeCode,
              cityCode: formData.city?.ibgeCode,
            });

          if ('data' in response && response.data) {
            this.suppliers = response.data.map((item) => ({
              optionLabel: item.name,
              optionValue: item.id,
            }));
          } else {
            this.suppliers = [];
          }
        } catch {
          // empty
        }

        return;
      }
      this.suppliers = [];
    },

    resetPickedOptions() {
      const { pickedOptions } = this;
      type PickedOption = keyof typeof pickedOptions;

      (Object.keys(this.pickedOptions) as PickedOption[]).forEach((option) => {
        this.pickedOptions[option] = false;
      });
    },

    establishmentOptionValue(e: { optionValue: string; companyName: string }) {
      if (!e) {
        return null;
      }

      return {
        id: e.optionValue,
        name: e.companyName,
      };
    },

    stateOptionValue(e: { code: number; uf: string; name: string }) {
      if (!e) {
        return null;
      }

      return {
        ibgeCode: e.code,
        uf: e.uf,
        name: e.name,
      };
    },

    cityOptionValue(e: { optionValue: string; codeIBGE: number; name: string }) {
      if (!e) {
        return null;
      }

      return {
        ibgeCode: e.codeIBGE,
        name: e.name,
      };
    },

    supplierOptionValue(e: { optionValue: string; optionLabel: string }) {
      if (!e) {
        return null;
      }

      return {
        id: e.optionValue,
        name: e.optionLabel,
      };
    },

    clearFilterText(component: any) {
      const dropdownRef = component as any;
      if (dropdownRef && dropdownRef.filterValue) {
        dropdownRef.filterValue = '';
      }
    },

    clearSelectedSupplier() {
      this.formData.supplier = null;
    },

    async cityLoadingHandler() {
      if (!this.initLoading) {
        this.signedLoading('cities', true);
        await this.loadCitiesByState();
        this.signedLoading('cities', false);

        const city = this.cities.find((item) => (
          item.codeIBGE === this.formData.city?.ibgeCode
        ));
        if (!city) {
          this.formData.city = null;
        }
      }
    },

    async suppliersLoadingHandler() {
      if (!this.initLoading) {
        this.signedLoading('suppliers', true);
        await this.loadSuppliers();
        this.signedLoading('suppliers', false);

        const supplier = this.suppliers.find((item) => (
          item.optionValue === this.formData.supplier?.id
        ));
        if (!supplier) {
          this.formData.supplier = null;
        }
      }
    },

    signedLoading(sign: string, add: boolean) {
      if (add) {
        this.multipleLoading.push(sign);
      } else {
        const index = this.multipleLoading.findIndex((item) => item === sign);
        if (index >= 0) {
          this.multipleLoading.splice(index, 1);
        }
      }
    },

    onChangeEstablishment() {
      const formDataEstablishment = this.formData.establishment;
      if (formDataEstablishment) {
        const establishment = this.establishments
          .find((item) => item.id === formDataEstablishment.id);

        const address = establishment?.address;

        if (!address) {
          return;
        }

        const state = this.states.find((item) => item.uf === address.state);

        if (!state) {
          this.formData.state = null;
          this.onChangeState();
          return;
        }

        if (!this.formData.state || this.formData.state.ibgeCode !== state.code) {
          this.formData.state = {
            ibgeCode: state.code,
            uf: state.uf,
            name: state.name,
          };
          this.onChangeState();
          return;
        }

        if (address.cityCode && this.formData.city?.ibgeCode !== address.cityCode) {
          this.formData.city = {
            ibgeCode: address.cityCode,
            name: address.city,
          };
          this.cityFieldDisabled = true;
        } else {
          this.formData.city = null;
          this.cityFieldDisabled = false;
        }
      }
    },

    onChangeState() {
      this.cityLoadingHandler();
    },

    onChangeCity(city: any) {
      if (!city) {
        this.formData.supplierType = '';
      } else {
        this.suppliersLoadingHandler();
      }
    },

    onChangeSupplierType(value: string) {
      if (value) {
        this.resetPickedOptions();

        // Se selecionado Prefeitura, exibir os campos Usuário, Senha, UF e Cidade.
        if (value === 'CITY_HALL') {
          this.pickedOptions.isCityHallPicked = true;
        } else {
          // this.formData.state = null;
          // this.formData.city = null;
        }
        // Se selecionado Água, exibir os campos Usuário, Senha, Unidade Consumidora.
        if (value === 'WATER') {
          this.pickedOptions.isWaterPicked = true;
        }
        // Se selecionado Energia, exibir os campos Usuário, Senha, Unidade Consumidora.
        if (value === 'ELECTRICAL_ENERGY') {
          this.pickedOptions.isEletricalEnergyPicked = true;
        }
        // Se selecionado Gás, exibir os campos Usuário, Senha, Unidade Consumidora.
        if (value === 'GAS') {
          this.pickedOptions.isGasPicked = true;
        }
        // Se selecionado Telecomunicação, exibir os campos Usuário, Senha, Unidade Consumidora.
        // Adicionar o campo Produto para o tipo Telecomunicação.
        // O campo Produto deve ser um select com as opções: Fixo, Móvel, Internet.
        if (value === 'TELECOMMUNICATION') {
          this.pickedOptions.isTelecommunicationPicked = true;
        } else {
          this.formData.telecommunicationProduct = '';
        }
      }
      this.suppliersLoadingHandler();
    },
  },

  watch: {
    multipleLoading(value: string[]) {
      this.handleLoading(value.length > 0);
    },
    'formData.city': {
      handler(value) {
        this.onChangeCity(value);
      },
    },
    'formData.supplierType': {
      handler(value) {
        this.onChangeSupplierType(value);
      },
    },
  },
});
