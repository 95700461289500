
import Vue from 'vue';
import ToggleMenu from '@/resources/profile/sections/ToggleMenu.vue';

export default Vue.extend({
  name: 'Avatar',

  components: {
    ToggleMenu,
  },
  props: {
    showToogleMenu: {
      type: Boolean,
      default: false,
    },

    photoUrl: {
      type: String,
      default: '',
    },

    loading: {
      type: Boolean as () => boolean,
      default: false,
    },
  },

  data() {
    return {
      url: '',
    };
  },

  watch: {
    url(newUrl) {
      this.$emit('update:photo-url', newUrl);
    },
  },

  created() {
    this.url = this.photoUrl;
  },

  methods: {
    changeAvatarUri(uri: string) {
      this.url = uri;
    },
  },

});
