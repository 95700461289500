
import mixins from 'vue-typed-mixins';
import { mapMutations, mapGetters } from 'vuex';

import PageCard from '@/common/template/PageCard.vue';
import Header from '@/resources/profile/sections/Header.vue';
import PersonalInfo from '@/resources/profile/sections/PersonalInfo.vue';
import Enterprise from '@/resources/profile/sections/Enterprise.vue';
import UserCredentials from '@/resources/profile/sections/UserCredentials.vue';
import UserAndPassword from '@/resources/profile/sections/UserAndPassword.vue';

import PageSection from '@/resources/profile/components/PageSection.vue';
import NotificationMixin from '@/mixins/NotificationMixin';

import ProfileService from '@/common/services/ProfileService';
import { Profile } from '@/types';

import { emptyProfile } from '@/resources/profile/mocks';

export default mixins(NotificationMixin).extend({
  name: 'Profile',

  components: {
    PageCard,
    Header,
    PersonalInfo,
    Enterprise,
    UserCredentials,
    PageSection,
    UserAndPassword,
  },

  data() {
    return {
      payload: emptyProfile as Profile,
      loading: true as boolean,
    };
  },

  computed: {
    ...mapGetters({
      token: 'auth/token',
      userLoggedIn: 'auth/userLoggedIn',
    }),
  },

  watch: {
    token(token: string): void {
      if (token && this.userLoggedIn) {
        this.getProfile();
      }
    },
  },

  mounted() {
    if (this.token && this.userLoggedIn) {
      this.getProfile();
    }
  },

  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),

    async getProfile(): Promise<void> {
      try {
        this.handleLoading(true);
        this.loading = true;

        this.payload = (await ProfileService.findProfile()).data.data;
      } catch (e) {
        this.errorToast({
          text: this.$t('managerProfile.getProfile.msgError') as string,
        });
      } finally {
        this.handleLoading(false);
        this.loading = false;
      }
    },
  },
});
