
import mixins from 'vue-typed-mixins';
import { debounce } from 'lodash';

export default mixins().extend({
  name: 'HeaderSection',

  data() {
    return {
      search: '',
    };
  },

  watch: {
    search(search: string) {
      const { length } = search.toString().trim();
      if (length === 0 || length > 1) {
        this.$emit('on:search', search);
      }
    },
  },

  methods: {
    showFormCreate() {
      this.$emit('on:show-form-create');
    },

    searchDebounce: debounce(
      // eslint-disable-next-line func-names
      async function (search) {
        // @ts-ignore
        this.search = search;
      },
      600,
    ),

  },
});
