
import Vue from 'vue';
import TitleComponent from '@/resources/profile/components/TitleComponent.vue';
import Field from '@/resources/profile/components/Field.vue';

import CnpjFormat from '@/common/filters/CnpjFormat';
import CepFormat from '@/common/filters/CepFormat';

import { CompanyProfile } from '@/types';

export default Vue.extend({
  name: 'Enterprise',

  components: {
    TitleComponent,
    Field,
  },

  filters: {
    CnpjFormat,
    CepFormat,
  },

  props: {
    enterprise: {
      type: Object as () => CompanyProfile,
      required: true,
    },

    loading: {
      type: Boolean as () => boolean,
      default: false,
    },
  },
  computed: {
    cnpjField(): string {
      return `${this.$t('managerProfile.fields.enterprise.cnpj.label')} ${
        this.enterprise.cnpj
      }`;
    },
    fullStreet(): string {
      const {
        street, number, additionalDetails, neighborhood,
      } = this.enterprise.address;
      let fullStreet = '';

      fullStreet = `${street}, ${number}`;

      if (additionalDetails) {
        fullStreet = fullStreet.concat(`, ${additionalDetails}`);
      }

      return fullStreet.concat(` - ${neighborhood}`);
    },
    fullStreetComplement(): string {
      const {
        zipCode, city, state, country,
      } = this.enterprise.address;

      return `${zipCode} - ${city}/${state} - ${country}`;
    },
  },
});
