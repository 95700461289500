<template>
  <div>
    <div>
      <el-dropdown trigger="click">
        <Button
          style="none-box-shadow"
          class="p-button-raised p-button-rounded p-shadow-4 button"
          icon="pi pi-camera"
        >
          <i class="icon-r-photo-camera" />
        </Button>

        <el-dropdown-menu>
          <el-dropdown-item
            v-for="(item, key) in items"
            :key="key"
            class="text-center"
            @click.native="item.command"
          >
            <img
              :src="item.icon"
              class="mr-2"
              :width="item.width"
              :height="item.height"
            >
            <span class="label">{{ item.label }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <form ref="form">
      <input
        v-show="false"
        ref="fileAvatar"
        type="file"
        accept="image/png, image/jpeg"
        @change="onFileChange"
      >
    </form>
  </div>
</template>

<script  >

import Vue from 'vue';

import editImage from '@/common/assets/icons/edit-image-blue-color.svg';
import trash from '@/common/assets/icons/trash.svg';
import NotificationMixin from '@/mixins/NotificationMixin';

export default Vue.extend({
  name: 'ToggleMenu',

  mixins: [NotificationMixin],
  data() {
    return {
      newUri: 'unknown',
      items: [
        {
          label: this.$t('managerProfile.toogleMenu.photoChange'),
          icon: editImage,
          command: () => this.$refs.fileAvatar.click(),
          heigth: 15,
          width: 13,
        },

        {
          label: this.$t('managerProfile.toogleMenu.photoDelete'),
          icon: trash,
          command: () => this.showModal(),
          heigth: 15,
          width: 13,
        },
      ],
    };
  },

  watch: {
    newUri(uri) {
      this.$emit('onChangeUri', uri);
    },
  },

  methods: {

    showModal() {
      if (this.newUri === '') {
        return;
      }

      this.confirmDelete({
        title: this.$t('managerProfile.toogleMenu.confirmationModal.deleteAvatar.title'),
        text: this.$t('managerProfile.toogleMenu.confirmationModal.deleteAvatar.message'),
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.removeAvatar();
        }
      });
    },

    removeAvatar() {
      this.newUri = '';
    },

    onFileChange(e) {
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.onloadend = () => this.newUri = reader.result;
      reader.readAsDataURL(file);

      this.$refs.form.reset();
    },

  },

});
</script>

<style lang="scss" scoped>
@import "@/common/assets/scss/_variables.scss";

.button, .button:hover{
  background-color: $white;
   border: none;
}

.icon-sm{
  background-color: $textError;
  border-radius: 100%;
  width: 20px;
  height: 20px;
}

.label{
  color: $black;
  font-family: $family-default;
}

.none-box-shadow{
  box-shadow: none;
}

.white100 {
  color: white !important;
  border-color: white  !important;
  border-width: 1px;
}

</style>
