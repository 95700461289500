var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("TitleComponent", {
        staticClass: "mb-2",
        attrs: {
          title: _vm.$t("managerProfile.fields.enterprise.company.label"),
        },
      }),
      _vm.loading
        ? _c(
            "div",
            [
              _c("b-skeleton", { attrs: { width: "25%" } }),
              _c("b-skeleton", { attrs: { width: "13%" } }),
              _c("b-skeleton", { attrs: { width: "25%" } }),
              _c("b-skeleton", { attrs: { width: "20%" } }),
            ],
            1
          )
        : _c(
            "div",
            [
              _vm.enterprise.name
                ? _c("Field", { attrs: { field: _vm.enterprise.name } })
                : _vm._e(),
              _vm.enterprise.cnpj
                ? _c("Field", {
                    attrs: {
                      title: _vm.$t(
                        "managerProfile.fields.enterprise.cnpj.label"
                      ),
                      field: _vm._f("CnpjFormat")(_vm.enterprise.cnpj),
                    },
                  })
                : _vm._e(),
              _vm.enterprise.address.street
                ? _c("Field", { attrs: { field: _vm.fullStreet } })
                : _vm._e(),
              _vm.enterprise.address.zipCode
                ? _c("Field", {
                    attrs: {
                      title: _vm.$t(
                        "managerProfile.fields.address.zipcode.label"
                      ),
                      field: _vm._f("CepFormat")(_vm.fullStreetComplement),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }