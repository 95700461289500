var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "cover-container" },
    [
      _vm.loading
        ? _c("b-skeleton-img", { attrs: { "no-aspect": "" } })
        : _c("img", {
            staticClass: "cover",
            attrs: {
              src:
                _vm.url || require("@/common/assets/images/fundo_header.png"),
              alt: _vm.$t("managerProfile.changeCover"),
            },
          }),
      _c("div", { staticClass: "button-upload-container" }, [
        _c("input", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
          ref: "file",
          attrs: { type: "file", accept: "image/png, image/jpeg" },
          on: { change: _vm.onFileChange },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }